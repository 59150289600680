var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_vm.providerDetails?.requestor?.requestedBy)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_setup.formatPhoneNumber(_vm.providerDetails?.requestor?.phone))+" ")])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_setup.formatPhoneNumber(_vm.providerDetails?.requestor?.fax))+" ")])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_vm.authData?.providerName)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_setup.networkStatus(_vm.providerDetails?.requestingProvider?.networkStatus))+" ")])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_vm.authData?.facilityName)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col-md-9 left mb-2 mt-2"},[_vm._v(" "+_vm._s(_setup.networkStatus(_vm.providerDetails?.facility?.networkStatus))+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Request By")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Phone")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Fax")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Requesting / Performing Provider")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Network Status")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Facility")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-3 left mb-2 mt-2"},[_c('strong',[_vm._v("Network Status")])])
}]

export { render, staticRenderFns }